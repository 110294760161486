import React, { useState } from 'react'
import TodoList from './TodoList';
import styled from 'styled-components'

const Input = styled.input`
height : 15px;
weidth : 15px:
textsize : 80px;
`

function App() {
  const [todoList, setTodoList] = useState(['task 1','555='])
  const [text, setText] = useState("")

  const onTextChange = ({ target: { value } }) => {
    setText(value)
  }

  const addTask = () => {
    setTodoList([
      ...todoList,
      text,
      setText("")
    ])
  }
  return (
    <div>
      <div>
      <Input type="text" value={text} onChange={onTextChange} />
      <button onClick={addTask}>Add text</button>
      </div>

  
    <div>
      <TodoList todoList={todoList}/>

    </div>

    <div/>

      
        
      

    </div>
    
  );
  
}

export default App;
