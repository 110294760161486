import React from 'react';

const Todo = ({value}) => {
    return (
        <div>
            {value}
        </div>
    )
}

export default Todo